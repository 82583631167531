import React, { useState, useEffect } from 'react';
import './FilterPane.css';

const FilterPane = ({ onFilterChange }) => {
    const [categories, setCategories] = useState([]); 
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [discountRange, setDiscountRange] = useState([0, 100]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('/data.json');
                const data = await response.json();
                setCategories(data.categories || []);
                console.log('categories:', data.categories); // Ensure it's an array
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(null); // Reset sub-category when changing the main category
        onFilterChange({ category, subCategory: null, priceRange, discountRange });
    };

    // const handleSubCategoryChange = (subCategory) => {
    //     setSelectedSubCategory(subCategory);
    //     onFilterChange({ category: selectedCategory, subCategory, priceRange, discountRange });
    // };

    const handlePriceChange = (event) => {
        const { min, max } = event.target.dataset;
        setPriceRange([min, max]);
        onFilterChange({ category: selectedCategory, subCategory: selectedSubCategory, priceRange: [min, max], discountRange });
    };

    const handleDiscountChange = (event) => {
        const { min, max } = event.target.dataset;
        setDiscountRange([min, max]);
        onFilterChange({ category: selectedCategory, subCategory: selectedSubCategory, priceRange, discountRange: [min, max] });
    };

    return (
        <div className='filter-pane'>
            {/* Categories and Sub-Categories */}
            <div className='filter-section'>
                <h3>Categories</h3>
                <ul>
                    {Array.isArray(categories) && categories.map((category) => (
                        <li key={category.id} onClick={() => handleCategoryChange(category)}>
                            {category.name}
                            {/* <ul>
                                {Array.isArray(category.subCategories) && category.subCategories.map((subCategory) => (
                                    <li key={subCategory.id} onClick={() => handleSubCategoryChange(subCategory)}>
                                        {subCategory.name}
                                    </li>
                                ))}
                            </ul> */}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Price Range */}
            <div className='filter-section'>
                <h3>Price Range</h3>
                <div>
                    <label>Min: </label>
                    <input type='number' value={priceRange[0]} onChange={(e) => handlePriceChange(e)} />
                    <label>Max: </label>
                    <input type='number' value={priceRange[1]} onChange={(e) => handlePriceChange(e)} />
                </div>
                <div>
                    <button data-min='0' data-max='100' onClick={handlePriceChange}>Less than GH₵100.00</button>
                    <button data-min='101' data-max='200' onClick={handlePriceChange}>GH₵101.00 - GH₵200.00</button>
                    <button data-min='201' data-max='500' onClick={handlePriceChange}>GH₵201.00 - GH₵500.00</button>
                    <button data-min='501' data-max='1000' onClick={handlePriceChange}>GH₵501.00 - GH₵1000.00</button>
                    <button data-min='1000' onClick={handlePriceChange}>GH₵1000+</button>
                    {/* Add more predefined ranges as needed */}
                </div>
            </div>

            {/* Discount Range */}
            <div className='filter-section'>
                <h3>Discount</h3>
                <button data-min='10' onClick={handleDiscountChange}>10% and above</button>
                <button data-min='20' onClick={handleDiscountChange}>20% and above</button>
                <button data-min='30' onClick={handleDiscountChange}>30% and above</button>
                <button data-min='40' onClick={handleDiscountChange}>40% and above</button>
                <button data-min='50' onClick={handleDiscountChange}>50% and above</button>
                <button data-min='60' onClick={handleDiscountChange}>60% and above</button>
                {/* Add more discount ranges as needed */}
            </div>
        </div>
    );
};

export default FilterPane;
