import React from 'react'
import './HeroNav.css'
import { RiHome6Line } from "react-icons/ri";
import { FaRegCompass } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import storeIcon from "../../assets/icons/hugeicons_store-01.svg"

const HeroNav = () => {
    return (
        <div className='heroNavContainer' >
            <Link to={'/'} className='homeButton'>
                <RiHome6Line size='1.4rem' className='homeIcon' />
                Home
            </Link>
            <Link className='discoverButton'>
                <FaRegCompass size='1.4rem' className='discoverIcon' />
                Discover
            </Link>
            <Link className='feedButton'>
                <img src={storeIcon} alt="store icon" className='storeIcon' />
                Feed
            </Link>
        </div>
    )
}

export default HeroNav
