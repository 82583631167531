import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const OrderDetails = () => {
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const { orderId } = useParams();

    const API_URL = '/data.json';

    // Fetch order details from API
    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                const data = await response.data;

                // filter the order by id
                const orderDetails = data.orders.filter((order) => order.id === parseInt(orderId, 10));
                setOrder(orderDetails[0]);

            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrderDetails();
    }, [orderId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!order) {
        return <p>Order not found.</p>;
    }

    return (
        <div className="order-details-container">
            {/* First Row: Product Details */}
            <div className="product-details">
                <img src={order.productImage} alt={order.productName} className="product-image" />
                <div className="product-info">
                <h2>{order.productName}</h2>
                <p>Unit Price: GH₵{order.unitPrice}</p>
                <p>Quantity: {order.quantity}</p>
                <p>Total Price: GH₵{order.total}</p>
                <p>Order Status: {order.status}</p>
                </div>
            </div>

            {/* Second Row: Delivery & Order Information */}
            <div className="delivery-order-info">
                {/* Left Column: Delivery Information */}
                <div className="delivery-info">
                <h3>Delivery Information</h3>
                <p>Estimated Delivery Date: {order.estimatedDeliveryDate}</p>
                <p>Delivery Method: {order.deliveryMethod}</p>
                <p>Buyer's Name: {order.buyerName}</p>
                </div>

                {/* Right Column: Order Information */}
                <div className="order-info">
                <h3>Order Information</h3>
                <p>Order Number: {order.orderNumber}</p>
                <p>Order Date: {new Date(order.date).toLocaleDateString()}</p>
                <p>Total Amount: GH₵{order.total}</p>
                </div>
            </div>

            {/* Third Row: Payment Information */}
            <div className="payment-info">
                <h3>Payment Information</h3>
                <p>Payment Method: {order.paymentMethod}</p>
                <div className="payment-details">
                <p>Item Price: GH₵{order.unitPrice}</p>
                <p>Delivery Fee: GH₵{order.deliveryFee}</p>
                <p>Total Amount: GH₵{order.total}</p>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails
