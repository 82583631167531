import React from 'react'
import './ProfileOverview.css'
import { FiUser } from "react-icons/fi";
import EmailIcon from '../../assets/icons/email.svg'
import PhoneIcon from '../../assets/icons/phone.svg'
import LocationIcon from '../../assets/icons/location_on.svg'
import ProfilePicture from '../../assets/icons/Frame 88.png'
import { GrCart } from 'react-icons/gr';
import { HiOutlineHeart } from "react-icons/hi"
import { PiBellBold } from "react-icons/pi";
import StoreIcon from "../../assets/icons/hugeicons_store-01.svg"


const ProfileOverview = () => {
    return (
        <div className='profile-overview-container'>
            <div className='profile-overview-title'>
                <h2>Overview</h2>
            </div>
            <div className='profile-overview-header'>
                <div className='profile-picture'>
                    <img src={ProfilePicture} alt='profile-picture' />
                </div>
                <div className='profile-info'>
                    <div className='profile-info-left'>
                        <div className='profile-name item'>
                            <FiUser />
                            <h3>John Doe</h3>
                        </div>
                        <div className='profile-email item'>
                            <img src={EmailIcon} alt='email-icon' />
                            <h3>john.doe@gmail.com</h3>
                        </div>
                    </div>
                    <div className='profile-info-right'>
                        <div className='profile-phone item'>
                            <img src={PhoneIcon} alt='phone-icon' />
                            <h3>+1 123 456 7890</h3>
                        </div>
                        <div className='profile-location item'>
                            <img src={LocationIcon} alt='location-icon' />
                            <h3>123 Main St, New York, NY</h3>
                        </div>
                    </div>
                    <button className='edit-profile-button'>Edit Profile</button>
                </div>
            </div>
            <span className='profile-overview-divider'></span>
            <div className='profile-overview-buttons'>
                <div className='link-button'>
                    <GrCart />
                    <p>Cart</p>
                </div>
                <div className='link-button'>
                    <HiOutlineHeart />
                    <p>Wishlist</p>
                </div>
                <div className='link-button'>
                    <PiBellBold />
                    <p>Notifications</p>
                </div>
                <div className='link-button'>
                    <img src={StoreIcon} alt='store-icon' />
                    <p>Following (12)</p>
                </div>
            </div>
            <span className='profile-overview-divider'></span>
            <div className='recent-orders-container'>
                <h3>Recent Orders</h3>
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Order ID</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Samsung S22 Ultra</td>
                                <td>#10003 </td>
                                <td>GHS 12,000</td>
                                <td>Delivered</td>
                            </tr>
                            <tr>
                                <td>Samsung S22 Ultra</td>
                                <td>#10003 </td>
                                <td>GHS 12,000</td>
                                <td>Delivered</td>
                            </tr>
                            <tr>
                                <td>Samsung S22 Ultra</td>
                                <td>#10003 </td>
                                <td>GHS 12,000</td>
                                <td>Delivered</td>
                            </tr>
                            <tr>
                                <td>Samsung S22 Ultra</td>
                                <td>#10003 </td>
                                <td>GHS 12,000</td>
                                <td>Delivered</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <span className='profile-overview-divider'></span>
            <div className='coupons-container'>
                <h3>Coupons</h3>
                <div className='coupons'>
                    <p><span>Oops!</span><span>You have no coupons</span></p>
                </div>
            </div>
        </div>
    )
}

export default ProfileOverview
