import React from 'react'
import './SignupForm.css'
import { useForm } from 'react-hook-form'

const SignupForm = ({role}) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = data => {
        console.log(`Signing up as ${role}`,data)
        // send data to server
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>Sign up as {role.charAt(0).toUpperCase() + role.slice(1)}</h3>
            <div>
                <label>Email</label>
                <input
                    {...register('email', { required: 'Email is required' })}
                    type="email"
                    placeholder="Enter your email"
                />
                {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div>
                <label>Password</label>
                <input
                    {...register('password', { required: 'Password is required' })}
                    type="password"
                    placeholder="Enter your password"
                />
                {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div>
                <label>Confirm Password</label>
                <input
                    {...register('confirmPassword', {
                        validate: (value) => value === watch('password') || 'Passwords do not match',
                    })}
                    type="password"
                    placeholder="Confirm your password"
                />
                {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
            </div>
            <button type="submit">Continue</button>
        </form>
    )
}

export default SignupForm
