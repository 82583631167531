import React, { useState } from 'react'
import './LoginForm.css'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from '@greatsumini/react-facebook-login'
import FacebookIcon from '../../assets/icons/Facebook-icon.svg'
import GoogleIcon from '../../assets/icons/Google-icon.svg'
import CheckIcon from '../../assets/icons/check-broken.svg'
import EyeIcon from '../../assets/icons/eye-closed.svg'
import PasswordResetForm from '../passwordResetForm/PasswordResetForm'
import { RotatingLines } from 'react-loader-spinner'
import { LuEye } from "react-icons/lu";
import axios from 'axios'

const BASE_URL = 'https://new-kokorko-backend-da79e2d7d8a3.herokuapp.com/' // get base url from environment variables

const LoginForm = ({role}) => {
    const { register, handleSubmit, formState: {errors}, setError } = useForm();
    const [showResetForm, setShowResetForm] = useState(false);
    const [apiErrors, setApiErrors] = useState({email: '', password: '', general: ''}); //store api errors
    const [showPassword, setShowPassword] = useState(false); //toggle password visibility
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


// form submission function
const onSubmit = async (data) => {
    setLoading(true);
    console.log('Login data submitted:', data);
    try {
        // Clear previous errors
        setApiErrors({ email: '', password: '', general: '' });

        // Send login data to backend
        const response = await axios.post(`${BASE_URL}api/login/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const result = response.data; // No need for await here
        console.log('Login result:', result);

        // Handle success if needed (e.g., save tokens, etc.)

        
    } catch (error) {
        if (error.response) {
            const result = error.response.data;

            if (result.errors) {
                result.errors.forEach((error) => {
                    setError(error.field, { type: 'server', message: error.message });
                    setApiErrors((prevErrors) => ({ ...prevErrors, [error.field]: error.message }));
                });
            } else {
                setApiErrors((prevErrors) => ({
                    ...prevErrors,
                    general: result.message || 'An error occurred. Please try again.',
                }));
            }
        } else {
            console.error('Login error', error.message);
        }
    } finally {
        setLoading(false);
        navigate(`/profile`); // Redirect to profile page
    }
};

const handleGoogleSuccess = (response) => {
    console.log('Google Login Successful:', response);
    // Handle Google OAuth 2.0 login here
};

const handleGoogleFailure = (response) => {
    console.log('Google Login Failed:', response);
};

const handleFacebookResponse = (response) => {
    console.log('Facebook Login Successful:', response);
    // Handle Facebook OAuth 2.0 login here
};

const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleFailure,
});

// function to show password
const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

return (
    <form onSubmit={handleSubmit(onSubmit)}>
        {!showResetForm ? (
            <div className='login-container'>
                <div className="oauth-buttons">
                    <button className='google-button' onClick={() => googleLogin()} disabled={loading}>
                        <img src={GoogleIcon} alt='Google icon' />
                        <span>Login with Google</span>
                    </button>
                    <div className='facebook-button'>
                        <img src={FacebookIcon} alt='Facebook icon' />
                        <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={handleFacebookResponse}
                            className='facebook-login'
                        />
                    </div>
                </div>
                <div className="or-separator">
                    <span className='or-separator-left'></span><span>OR</span><span className='or-separator-right'></span>
                </div>
                <div className='input-fields'>
                    <div className={`input-field ${errors.email || apiErrors.email ? 'error' : ''}`}>
                        <label>Email</label>
                        <div className='email-field'>
                            <input
                                {...register('email', { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' } })}
                                type='email'
                                placeholder='Enter your email'
                            />
                            <img src={CheckIcon} alt='Check icon' className={`${errors.email ? 'error-icon' : ''}`} />
                        </div>
                        {errors.email && <p className='error-message'>{errors.email.message}</p>}
                        {apiErrors.email && <p className='error-message'>{apiErrors.email}</p>}
                    </div>
                    <div className={`input-field ${errors.password || apiErrors.password ? 'error' : ''}`}>
                        <label>Password</label>
                        <div className='password-field'>
                            <input
                                {...register('password', { required: 'Password is required' })}
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter your password'
                            />
                            <div onClick={togglePasswordVisibility}>
                                {showPassword ? <LuEye size='1.86rem' className={`eye-icon ${errors.password ? 'error-icon' : ''}`} /> : <img src={EyeIcon} alt='Eye icon' className={`${errors.password ? 'error-icon' : ''}`} />}
                            </div>
                        </div>
                        {errors.password && <p className='error-message'>{errors.password.message}</p>}
                        {apiErrors.password && <p className='error-message'>{apiErrors.password}</p>}
                    </div>
                </div>
                {apiErrors.general && <p className="error-message">{apiErrors.general}</p>}
                <div>
                    <p className='forgot-password' onClick={() => setShowResetForm(true)}>Forgot your password?{' '}</p>
                </div>
                <button className='login-button' type='submit' disabled={loading}>
                    {loading ? <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="24"
                        color="grey"
                        visible={true}
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /> : 'Continue'}
                </button>
            </div>
        ) : (
            <div>
                <PasswordResetForm />
            </div>
        )}
    </form>
);
};

export default LoginForm
