import React from 'react'
import './ViewAllButton.css'

const ViewAllButton = () => {
    return (
        <div>
            <button className="view-all-button">View All</button>
        </div>
    )
}

export default ViewAllButton
