import React from 'react'
import HeroSection from '../containers/heroSection/HeroSection'
import './Main.css'
import PopularCats from '../containers/popularCats/PopularCats'
import BestDeals from '../containers/bestDeals/BestDeals'
import Stores from '../containers/stores/Stores'
import TopPicks from '../containers/topPicks/TopPicks'
import BestSeller from '../containers/bestSeller/BestSeller'
import FlashDeals from '../containers/flashDeals/FlashDeals'


function Main() {
    return (
        <div className='main'>
            <HeroSection />
            <div className='popularCats'>
                <h1>Popular Categories</h1>
                <PopularCats />
            </div>
            <BestDeals />
            <Stores />
            <TopPicks />
            <BestSeller />
            <FlashDeals />
        </div>
    ) 
}

export default Main
