import React from 'react'
import './PopularCats.css'
import { useState, useEffect } from 'react'
import {v4 as uuidv4} from 'uuid'
import { Link } from 'react-router-dom'


const PopularCats = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [popularcats, setPopularcats] = useState([]);

    useEffect(() => {
        const getPopularCats = async () => {
            setLoading(true);
            try {
                const response = await fetch('/data.json'); //TODO: replace with API call
                const data = await response.json(); //await response.
                console.log('Popular Cats: ', data);
                setPopularcats(data);

                 // Ensure that `data.popularcats` is an array
                if (Array.isArray(data.popularcats)) {
                    setPopularcats(data.popularcats);
                } else {
                    setError('Unexpected data format');
                }
                
            } catch (error) {
                setError ('Error loading data')
            }
            setLoading(false);
        }
        getPopularCats();
    }, [])

    if(loading) {
        return (
            <div>Loading...</div>
        )
    }

    if(!popularcats) {
        return (
            <div>
                {error &&
                    <div>
                        <p>Sorry, we had a problem loading the data. Please try again</p>
                    </div>
                }
            </div>
        )
    } else return (
        <Link to={'/products'} className='popular-cats'>
            {popularcats.map((category) => { 
                const {name, image, description} = category;
                return (
                    <div key={uuidv4()} className="category">
                        <div className='image-section'>
                            <div className="image-container">
                                <img src={image} alt={name} />
                            </div>
                        </div>
                        <h3>{description}</h3>
                    </div>
                )
            })}
        </Link>
    )
    
}

export default PopularCats
