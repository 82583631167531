import React from 'react'
import "./BestSellersPage.css"

const BestSellers = () => {
    return (
        <div className='bestSellersPage'>
            <h1>Best Sellers Page</h1>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default BestSellers
