import React from 'react'
import './CatMenuModal.css'

const CatMenuModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null // render nothing if the modal is not open
    return (
        <div className='cat-modal' onClick={onClose}>
            <div className='cat-modal-content' onClick={e => e.stopPropagation()}>
                {children}
                <button className='close-btn' onClick={onClose}>X</button>
            </div>
        </div>
    )
}

export default CatMenuModal
