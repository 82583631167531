import React from 'react'
import './PasswordResetForm.css'
import { useForm } from 'react-hook-form'
import { RotatingLines } from 'react-loader-spinner'

const PasswordResetForm = () => {
    const { register, handleSubmit, formState: {errors}} = useForm();
    const [loading] = React.useState(false)

    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <form className='password-reset-form' onSubmit={handleSubmit(onSubmit)}>
            <h3>Reset your password</h3>
            <p>Enter your email and we'll send you a reset link.</p>
            <div className='input-field'>
                <label>Email</label>
                <div className='email-field'>
                    <input
                        {...register('email', {required: 'Email is required', pattern: {value: /^\S+@\S+$/i, message: 'Invalid email'}})}
                        type="email"
                        placeholder="Enter your email"
                    />
                </div>
                {errors.email && <p className='error-message'>{errors.email.message}</p>}
            </div>

            <button type="submit"
                className='login-button' disabled={loading}>
                { loading ? <RotatingLines 
                            strokeColor="white" 
                            strokeWidth="5" 
                            animationDuration="0.75" 
                            width="24"
                            color="grey" 
                            visible={true}
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            /> : 'Send Reset Link' }
            </button>
        </form>
    )
}

export default PasswordResetForm
