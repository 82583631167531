import React from 'react'
import './ProductCard.css'
import { FiShoppingCart } from 'react-icons/fi';
import { HiOutlineHeart } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const findDiscountPercentage = (price, discount) => {
    if(price <= 0) return 0;
    return ((price - discount)/price) * 100;
};

const ProductCard = ({ id, name, description, image, price, rating, review, discount, category, seller,
    showName=true, showDescription=true, showPrice=true, showRating=true, showReview=true, showDiscount=true, 
    showCategory=true, showSeller=true, showCartIcon=false, showHeartIcon=false, showDiscountPercentage=true, showButton=true, className=''
    }) => {

        const discountPercentage = findDiscountPercentage(price, discount);

        const navigate = useNavigate();

        const handleCardClick = () => {
            navigate(`/product/${id}`);
        };

        const handleIconClick = (e, url) => {
            e.stopPropagation();
            navigate(url);
        }

    return (
        <div className={`product-card ${className}`} onClick={handleCardClick}>
            <div className='product-card-image'>
            { showHeartIcon && (
                <div className='product-card-icon-heart' onClick={(e) => handleIconClick(e, `/wishlist/add/${id}`)}>
                    <HiOutlineHeart size='1.3rem' />
                </div>
            )}
                <img src={image} alt={name} className="product-image" />
            </div>
            { showCartIcon && (
                <div className='product-card-icon-cart' onClick={(e) => handleIconClick(e, `/cart/add/${id}`)}>
                    <FiShoppingCart size='1.2rem' />
                </div>
            )}
            <div className='product-card-info'>
                {showName && <h3 className="product-name">{name}</h3>}
                {showDescription && <h3 className="product-description">{description}</h3>}
                {showDiscount && <p className="product-discount">GH₵ {discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>}
                <div className='product-price-container'>
                    {showPrice && <p className="product-price">GH₵ {price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>}
                    {showDiscountPercentage && discountPercentage > 0 && (<p className="product-discount-percentage">-{discountPercentage.toFixed(0)}%</p>)}
                </div>
                {showReview && <p className="product-review">{review}</p>}
                {showRating && <p className="product-rating">{rating}/5</p>}
                {showCategory && <p className="product-category">{category}</p>}
                {showSeller && <p className="product-seller">{seller}</p>}
                {showButton && (    
                    <div className='product-card-actions'>
                        <button className='product-card-button'>Buy Now</button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductCard
