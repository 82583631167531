import React from 'react'
import './MainBanner.css'
import mainBanner from '../../assets/images/main-banner.png'


const MainBanner = () => {
    return (
        <div className='main-banner'>
            <img src={mainBanner} alt="main banner" />
        </div>
    )
}

export default MainBanner
