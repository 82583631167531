import React, { useState } from 'react'
import './HeroCatsMenu.css'
import { IoMenu } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import CatMenuModal from '../catMenuModal/CatMenuModal';
// import { Link } from 'react-router-dom';

const HeroCatsMenu = () => {
    const [isModalOpen, setModalOpen] = useState (false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <div>
            <div className='heroCatsMenu' onClick={openModal}>
                <IoMenu size='1.4rem' />
                <h1>Shop by Categories</h1>
                <IoChevronDown size='1.4rem' />
            </div>
            <CatMenuModal isOpen={isModalOpen} onClose={closeModal}>
                <h1>Categories Menu Modal</h1>
                <h2>coming soon</h2>
            </CatMenuModal>
        </div>
    )
}

export default HeroCatsMenu
