import React, { useState, useEffect } from 'react'
import './Reviews.css'
import ReviewsModal from '../reviewsModal/ReviewsModal'
import axios from 'axios'

const Reviews = () => {
    const [fulfilledOrders, setFulfilledOrders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [rating, setRating] = useState(0);
    const [recommend, setRecommend] = useState(null);
    const [reviewText, setReviewText] = useState('');
    const [loading, setLoading] = useState(false);

    // fetch fulfilled orders
    useEffect(() => {
        const getFulfilledOrders = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = await response.data;

                if (Array.isArray(data.orders)) {
                    // filter orders that are fulfilled
                    const deliveredOrders = data.orders.filter((order) => order.status === 'delivered');
                    setFulfilledOrders(deliveredOrders);
                }
            } catch (error) {
                console.error('Error fetching fulfilled orders:', error);
            } finally {
                setLoading(false);
            }
            
        }
        getFulfilledOrders();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    // open modal for review
    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setShowModal(true);
    };

    // close modal for review
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedOrder(null);
        setRating(0);
        setRecommend(null);
        setReviewText('');
    };

    // handle rating change
    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    // handle form submission
    const handleSubmitReview = () => {
        if (rating === 0 || recommend === null || reviewText.trim() === '') {
            alert('Please fill in all fields');
            return;
        }

        const review = {
            orderId: selectedOrder.id,
            rating,
            recommend,
            reviewText,
        };
        console.log('Review Submitted:', review);

        // Perform review submission logic here (API call)

        handleCloseModal();
    };

    return (
        <div className="review-container">
            <h2>Review Purchases</h2>
            {setFulfilledOrders.length === 0 ? (
                <p>No fulfilled orders to review.</p>
            ) : (
                <div className="fulfilled-orders-list">
                    {fulfilledOrders.map((order) => (
                        <div className="order-item" key={order.id}>
                            <img src={order.productImage} alt={order.productName} className="product-image" />
                            <div>
                                <h3>{order.productName}</h3>
                                <p>Price: GH₵{order.unitPrice}</p>
                                <p>Quantity: {order.quantity}</p>
                                <p>Total Amount: GH₵{order.total}</p>
                            </div>
                            <div>
                                <p>Order Number: {order.orderNumber}</p>
                                <p>Date: {order.date}</p>
                                <button className="review-now-btn" onClick={() => handleOpenModal(order)}>
                                    Review Now
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Modal for submitting review */}
            {selectedOrder && (
                <ReviewsModal showModal={showModal} onClose={handleCloseModal}>
                    <div className="review-modal-content">
                        <div className='review-modal-header-product'>
                            <img src={selectedOrder.productImage} alt={selectedOrder.productName} className="product-image" />
                            <div>
                                <h2>{selectedOrder.productName}</h2>
                                <p>Quantity: {selectedOrder.quantity}</p>
                                <p>Price: GH₵{selectedOrder.unitPrice}</p>
                            </div>
                            <div>
                                <p>Total Amount: GH₵{selectedOrder.total}</p>
                                <p>Order Number: {selectedOrder.orderNumber}</p>
                            </div>
                        </div>

                        {/* Rating Row */}
                        <div className="rating-row">
                            <h4>Rating:</h4>
                            <div className="stars">
                                {[1, 2, 3, 4, 5].map((value) => (
                                    <span
                                        key={value}
                                        className={`star ${value <= rating ? 'selected' : ''}`}
                                        onClick={() => handleRatingChange(value)}
                                    >
                                        ★
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* Recommendation Checkbox */}
                        <div className="recommend-row">
                            <h4>Would you recommend this merchant to anyone?</h4>
                            <div className="recommend-options">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="recommend"
                                        value="yes"
                                        checked={recommend === 'yes'}
                                        onChange={() => setRecommend('yes')}
                                    />{' '}
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="recommend"
                                        value="no"
                                        checked={recommend === 'no'}
                                        onChange={() => setRecommend('no')}
                                    />{' '}
                                    No
                                </label>
                            </div>
                        </div>

                        {/* Review Text Box */}
                        <div className="review-text-row">
                            <h4>Your Review:</h4>
                            <textarea
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                                placeholder="Write your review here..."
                            />
                        </div>

                        {/* Modal Actions */}
                        <div className="modal-actions">
                            <button className="cancel-btn" onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className="submit-review-btn" onClick={handleSubmitReview}>
                                Submit
                            </button>
                        </div>
                    </div>
                </ReviewsModal>
            )}
        </div>
    )
}

export default Reviews
