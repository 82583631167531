import React from 'react'
import './HeroSection.css'
import HeroTop from '../heroTop/HeroTop'
import HeroBottom from '../heroBottom/HeroBottom'

const HeroSection = () => {
    return (
        <div>
            <HeroTop />
            <HeroBottom />
        </div>
    )
}

export default HeroSection
