import React from 'react'
import './Suggestions.css'

const Suggestions = () => {
    return (
        <div className='suggestions'>
            <h1>Suggestions</h1>
            <h2>Help us build a better shopping experience!</h2>
            <p>
            Kokorko’s suggestion box is wide open, and we’re eager to hear your thoughts on making it the best shopping app ever.   
            </p>
            <p>Share your thoughts below</p>

            <textarea name="suggestions" id="suggestions" cols="30" rows="10"></textarea>
            <button>Submit</button>
            
        </div>
    )
}

export default Suggestions
