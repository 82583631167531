import React from 'react'
import './ProductDetails.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import HeroTop from '../../containers/heroTop/HeroTop'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'


const ProductDetails = () => {
    const[product, setProduct] = useState([]);
    const[loading, setLoading] = useState(false);
    const {id} = useParams();
    const[error, setError] = useState(null);


    useEffect(() => {
        const getProductDetails = async () => {
            setLoading(true);
            try {
                const response = await fetch('/data.json');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setProduct(data);

                if (data && data.products) {
                    const product = data.products.find(p => p.id === parseInt(id))
                    if(product) {
                    setProduct(product);
                    } else {
                        setError('Product not found');
                    }
                } else {
                    setError('Unexpected data format');
                }
            } catch (e) {
                setError('Error loading data');
            }
            setLoading(false);
        };
        getProductDetails();
    }, [id])

    if(loading) {
        return <div>Loading...</div>
    }

    if (!product) {
        return (
            <div>
                { error &&
                    <p>Error loading page. Please refresh.</p>
                }
            </div>
        )
    } else return (
        <div className='product-details-container'>
            <div>
                <HeroTop />
            </div>
            <div>
                <Breadcrumb />
            </div>
            <div className='product-details'>
                <div className='top-container'>
                    <div className='product-image'>
                        <img src={product.image} alt={product.name} />
                    </div>
                    <div className='product-info'>
                        <p>{product.seller}</p>
                        <h1>{product.name}</h1>
                        <p>{product.rating}</p> 
                        <p>{product.discount}</p>
                        <p>{product.price}</p>
                    </div>
                    <div className='checkout-info'>
                        <h2>Delivery Information</h2>
                    </div>
                </div>
                <div>
                    <h2>Product Description</h2>
                    <p>{product.description}</p>
                </div>
                <hr />
                <div>
                    <h2>Specification</h2>
                </div>
                <hr />
                <div>
                    <h2>Customer Reviews</h2>
                    <p>{product.reviews}</p>
                </div>
                <hr />
                <div>
                    <h2>Similar Products</h2>
                </div>
            </div>
        </div>
    )

}

export default ProductDetails
