import React from 'react'
import './Breadcrumb.css'
import { Link, useLocation } from 'react-router-dom'

const Breadcrumb = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('>').filter(x => x);

    const breadcrumbNameMap = {
        '/best-deals-all': 'Best Deals', 
        '/stores': 'Stores',
        '/top-picks': 'Top Picks',
        '/best-sellers': 'Best Sellers',
        '/flash-deals': 'Flash Deals',
        '/categories': 'Categories',
        '/products': 'Products',
        '/product': 'Product'
    };

    return (
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
                <li className='breadcrumb-item active'>
                    <Link to='/'>Home</Link>
                </li>
                {
                    pathnames.map((value, index) =>{
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        const name = breadcrumbNameMap[to] || value;

                        return (
                            <li key={to} className='breadcrumb-item'>
                                <Link to={to}>{name}</Link>
                                {index === pathnames.length - 1 && <span className="breadcrumb-separator"> &gt; </span>}
                            </li>
                        )
                    })
                }
            </ol>
        </nav>
    )
}

export default Breadcrumb
