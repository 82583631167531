import React from 'react'
import './BestDealsPage.css'

const BestDealsPage = () => {
    return (
        <div className='best-deals'>
            <h1>Best Deals Page</h1>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default BestDealsPage
