import React from 'react'
import './Stores.css'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { Link } from 'react-router-dom'
import watchIcon from '../../assets/icons/WatchIcon_.svg'

const Stores = () => {
    return (
        <div className='stores'>
            <div className='stores-header'>
                <h1>Stores</h1>
                <Link to={'./stores'}><ViewAllButton /></Link>
            </div>
            <div className='stores-container'>
                <div className='store'>
                    <div className='store-name'>
                        <h3>Gadget Galaxy</h3>
                        <img src={watchIcon} alt='watch' />
                    </div>
                    <div className='store-img-watch'>
                        <div className='btn-container'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <button>Follow</button>
                    </div>
                </div>
                <div className='store'>
                    <div className='store-name'>
                        <h3>Kicks Kingdom</h3>
                        <img src={watchIcon} alt='watch' />
                    </div>
                    <div className='store-img-kicks'>
                        <div className='btn-container'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <button>Follow</button>
                    </div>
                </div>
                <div className='store'>
                    <div className='store-name'>
                        <h3>Sneaker Society</h3>
                        <img src={watchIcon} alt='watch' />
                    </div>
                    <div className='store-img-sneakers'>
                        <div className='btn-container'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <button>Follow</button>
                    </div>
                </div>
                <div className='store'>
                    <div className='store-name'>
                        <h3>Tech Haven</h3>
                        <img src={watchIcon} alt='watch' />
                    </div>
                    <div className='store-img-laptop'>
                        <div className='btn-container'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <button>Follow</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stores
