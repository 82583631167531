import React from 'react'
import './Help.css'
import FAQIcon from '../../assets/icons/FAQ chat.svg'
import ChatIcon from '../../assets/icons/message-text-01.svg'
import ChevronRightIcon from '../../assets/icons/chevron-right.svg'
import QuestionMarkIcon from '../../assets/icons/material-symbols_help-outline.svg'
import PhoneIcon from '../../assets/icons/phone.svg'

const Help = () => {
    return (
        <div className='help'>
            <h1>Help</h1>
            <p>Got quetions? We’re here to help.</p>
            <div className='help-buttons-container'>
                <div className='help-button'>
                    <img src={FAQIcon} alt='FAQ' />
                    <p>Frequently Asked Questions</p>
                    <img src={ChevronRightIcon} alt='chevron-right' />
                </div>
                <div className='help-button'>
                    <img src={QuestionMarkIcon} alt='question-mark' />
                    <p>Visit Help Center</p>
                    <img src={ChevronRightIcon} alt='chevron-right' />
                </div>
                <div className='help-button'>
                    <img src={ChatIcon} alt='chat' />
                    <p>Chat with Us</p>
                    <img src={ChevronRightIcon} alt='chevron-right' />
                </div>
                <div className='help-button'>
                    <img src={PhoneIcon} alt='phone' />
                    <p>Call Us</p>
                    <img src={ChevronRightIcon} alt='chevron-right' />
                </div>
            </div>
        </div>
    )
}

export default Help
