import React from 'react'
import './WishlistModal.css'

const WishlistModal = ({showModal, onClose, onDelete, itemName}) => {
    if (!showModal) {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-container">
                <div className='modal-header'>
                    <h2>Remove Item: {itemName}?</h2>
                    <div onClick={onClose}>X</div>
                </div>
                <p>Are you sure you want to remove this item from your wishlist?</p>
                <div className="modal-actions">
                    <button className="modal-cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="modal-delete-btn" onClick={onDelete}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WishlistModal
