import React from 'react'
import './ReviewsModal.css'

const ReviewsModal = ({ showModal, onClose, children }) => {
    return (
        <div className="review-modal-backdrop">
            <div className='review-modal-container'>
                <div className='review-modal-header'>
                    <h3>Rate Product</h3>
                    <button className="close-btn" onClick={onClose}>X</button>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ReviewsModal
