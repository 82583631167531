import React from 'react'
import './BestDeals.css'
import { Link } from 'react-router-dom'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { useState, useEffect } from 'react'
import ProductCard from '../../components/productsCard/ProductCard'
import { v4 as uuidv4 } from 'uuid'

const BestDeals = () => {
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    const[products, setProducts] = useState([]);
    const bestDealsLeftProduct = [1]; // this is the id of the product that will be displayed on the left side of the best deals section
    const bestDealsRightProductTop = [2]; // this is the id of the product that will be displayed on the right side of the best deals section
    const bestDealsLeftProductBottom = [3]; // this is the id of the product that will be displayed on the left side of the best deals section

    useEffect (() => {
        const getProduct = async () => {
            setLoading(true);
            try{
                const response = await fetch('/data.json');
                const data = await response.json();

                setProducts(data);
                if(Array.isArray(data.products)) {
                    setProducts(data.products);

                    // console.log('products:', data.products);
                } else {
                    setError('Unexpected data format');
                }
            } catch(error) {
                setError('Error loading data');
            }
            setLoading(false);
        };
        getProduct();
    }, [])

    const filteredProductsLeft = products.filter((product) => bestDealsLeftProduct.includes(product.id));
    const filteredProductsRightTop = products.filter((product) => bestDealsRightProductTop.includes(product.id));
    const filteredProductsRightBottom = products.filter((product) => bestDealsLeftProductBottom.includes(product.id));


    if(loading) {
        return <div>Loading...</div>
    }

    if(!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='bestDeals'>
            <div className='bestDeals-header'>
                <h1>Best Deals for Today</h1>
                <Link to={'./best-deals-all'}><ViewAllButton /></Link>
            </div>
            <div className='products'>
                <div className='left'>
                    {
                        filteredProductsLeft.map((product) => {
                            return (
                                <ProductCard 
                                    key={uuidv4()}
                                    id={product.id}
                                    name={product.name}
                                    description={product.description}
                                    price={product.price}
                                    discount={product.discount}
                                    image={product.image}
                                    category={product.category}
                                    rating={product.rating}
                                    reviews={product.reviews}
                                    showName={true}
                                    showDescription={true}
                                    showPrice={true}
                                    showDiscount={true} 
                                    showDiscountPercentage={true}
                                    showRating={false}
                                    showReview={false} 
                                    showCategory={false} 
                                    showSeller={false} 
                                    showButton={true}
                                    className='best-deals-left'
                                />
                            )
                        })
                    }
                </div>
                <div className='right'>
                    <div className='right-top'>
                        {
                            filteredProductsRightTop.map((product) => {
                                return (
                                    <ProductCard 
                                        key={uuidv4()}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={true} 
                                        showDiscountPercentage={true}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        className='best-deals-right'
                                    />
                                )
                            })
                        }
                    </div>
                    <div className='right-bottom'>
                        {
                            filteredProductsRightBottom.map((product) => {
                                return (
                                    <ProductCard 
                                        key={uuidv4()}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={true} 
                                        showDiscountPercentage={true}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false}
                                        showButton={false}
                                        className='best-deals-right'
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BestDeals
