import React from 'react'
import './Messages.css'

const Messages = () => {
    return (
        <div>
            <h2>Messages</h2>
            <p>Welcome to your messages overview.</p>
            {/* Add more details here */}
        </div>
    )
}

export default Messages
