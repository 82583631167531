import React from 'react'
import './TopPickPage.css'

const TopPicksPage = () => {
    return (
        <div className='top-picks-page'>
            <h1>Top Picks Page</h1>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default TopPicksPage
