import React from 'react'
import './BestSeller.css'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { Link } from 'react-router-dom'

const BestSeller = () => {
    return (
        <div className='bestSeller'>
            <div className='bestSeller-header'>
                <h1>Best Sellers</h1>
                <Link to={'./best-sellers'}><ViewAllButton /></Link>
            </div>
            <div className='bestSeller-container'></div>
        </div>
    )
}

export default BestSeller
