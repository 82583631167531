import React, { useState, useEffect, useRef } from "react";
import "./Search.css";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import openIcon from '../../assets/icons/Union.svg'


const Search = ({products =[]}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const searchRef = useRef(null);

    // Helper function to sanitize and search products
    const filterProducts = (searchTerm) => {
        if (searchTerm.trim() === "") return [];
        
        return products.filter(product => {
            // Ensure product has a valid name and filter based on search term
            const name = product?.name?.toLowerCase();
            if (!name) return false; // Skip products without a valid name
            return name.includes(searchTerm.toLowerCase().trim());
        });
    };

    // filter products based on search term
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchTerm.trim()) {
                const filteredProducts = filterProducts(searchTerm);
                setSearchResults(filteredProducts);
            } else {
                setSearchResults([]);
            }
        }, 300);
        return () => clearTimeout(timeoutId);
    });

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleProductClick = (products) => {
        console.log("Selected Product: ", products);
        navigate(`/category/${products.category}`); // navigate to the product category page
        setSearchTerm(""); //reset search term
        setSearchResults([]); //clear search results
    };    

    // handle click outside search bar to reset the search form
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm("");
                setSearchResults([]);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    // handle Esc key press to reset search form
    useEffect(() => {
        const handleEscKey = (e) => {
            if (e.key === 'Escape') {
                setSearchTerm("");
                setSearchResults([]);
            }
        };
        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, []);


    // render search results

    return (
        <div className="searchContainer" ref={searchRef} >
            <CiSearch size='1.5rem' />
            <input type="text" 
                value={searchTerm} 
                onChange={handleSearch}
                id='search' 
                placeholder="What are you looking for today?" 
            />
            {searchTerm && searchResults.length > 0 && (
                <ul className="searchResults">
                    {searchResults.map(product => (
                        <div key={product.id} className="searchResultItemContainer">
                            <li className="searchResultItem" onClick={() => handleProductClick(product)}>{product.name}</li>
                            <img src={openIcon} alt="open icon" className="openIcon" />
                        </div>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Search;