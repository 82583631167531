import React from 'react'
import './HeroBottom.css'
import MainBanner from '../../components/mainBannerCard/MainBanner'
import SideBanner from '../../components/sideBannerCard/SideBanner'

const HeroBottom = () => {
    return (
        <div className='heroBottom'>
            <div className='heroBottom__left'>
                <MainBanner />
            </div>
            <div className='heroBottom__right'>
                <SideBanner />
            </div>
        </div>
    )
}

export default HeroBottom
