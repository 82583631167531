import React from 'react'
import './HeroTop.css'
import HeroCatsMenu from '../../components/heroCatsMenu/HeroCatsMenu'
import HeroNav from '../../components/heroNav/HeroNav'

const HeroTop = () => {
    return (
        <div className='heroTop'>
            <HeroCatsMenu />
            <HeroNav />
        </div>
    )
}

export default HeroTop
