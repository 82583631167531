import React from 'react'
import './TopPicks.css'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { Link } from 'react-router-dom'

const TopPicks = () => {
    return (
        <div className='topPicks'>
            <div className='topPicks-header'>
                <h1>Top Picks for you</h1>
                <Link to={'./top-picks'}><ViewAllButton /></Link>
            </div>
            <div className='topPicks-container'></div>
        </div>
    )
}

export default TopPicks
