import React from 'react'
import { useState, useEffect } from 'react'
import './CategoryPage.css'
import { useParams } from 'react-router-dom'
import ProductCard from '../../components/productsCard/ProductCard'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import HeroNav from '../../components/heroNav/HeroNav'
import FilterPane from '../../components/filterPane/FilterPane'


const CategoryPage = () => {
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    const[products, setProducts] = useState([]);
    const { category } = useParams();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        category: null,
        subCategory: null,
        priceRange: [0, 1000], // Default price range
        discountRange: [0, 100], // Default discount range
    });


    useEffect (() => {
        const getProducts = async () => {
            setLoading(true);
            try{
                // const response = await axios.get(`${BASE_URL}api/products/`);
                // const data = await response.data;
                const response = await fetch('/data.json');
                const data = await response.json();

                setProducts(data);
                
                if(Array.isArray(data.products)) {  
                    const categoryProducts = data.products.filter(
                        (product) => product.category === parseInt(category, 10));

                    setProducts(categoryProducts);  

                } else {
                    setError('Unexpected data format');
                }

            } catch(error) {
                setError('Error loading data');
            }

            setLoading(false);
        };
        getProducts();
    },[category]);

    // Filtering Logic
    useEffect(() => {
        let filtered = products;

        // Category Filter
        if (filterCriteria.category) {
            filtered = filtered.filter((product) => product.category === filterCriteria.category.id);
        }
        // Price Range Filter
        filtered = filtered.filter(
            (product) => product.price >= filterCriteria.priceRange[0] && product.price <= filterCriteria.priceRange[1]
        );

        // Discount Range Filter
        filtered = filtered.filter(
            (product) =>
                ((product.discount / product.price) * 100) >= filterCriteria.discountRange[0] &&
                ((product.discount / product.price) * 100) <= filterCriteria.discountRange[1]
        );

        setFilteredProducts(filtered);
    }, [filterCriteria, products]);

    const handleFilterChange = (newCriteria) => {
        setFilterCriteria(newCriteria);
    }

    if(loading) {
        return <div>Loading...</div>
    }

    if(!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='category-page-container'>
            <div className='category-page-breadcrumb'>
                <div>
                    <Breadcrumb />
                </div>
                <div>
                    <HeroNav />
                </div>
            </div>
            <div  className='category-page-content'>
                <div className='left-filter-pane'>
                    <FilterPane categories={products} onFilterChange={handleFilterChange} />
                </div>
                <div className='category-products'>
                    {filteredProducts.map((product) => (
                        <ProductCard 
                            key={product.id}
                            id={product.id}
                            name={product.name}
                            description={product.description}
                            price={product.price}
                            discount={product.discount}
                            image={product.image}
                            category={product.category}
                            rating={product.rating}
                            reviews={product.reviews}
                            showName={true}
                            showDescription={false}
                            showPrice={true}
                            showDiscount={false} 
                            showDiscountPercentage={false}
                            showRating={false}
                            showReview={false} 
                            showCategory={false} 
                            showSeller={false} 
                            showButton={false}
                            showCartIcon={true}
                            showHeartIcon={true}
                            className='product-page-card'
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CategoryPage
