import React from 'react'
import './Settings.css'

const Settings = () => {
    return (
        <div>
            <h2>Settings</h2>
            <p>Welcome to your settings overview.</p>
            {/* Add more details here */}
        </div>
    )
}

export default Settings
