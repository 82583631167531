import React, { useEffect } from 'react'
import './ProfilePage.css'
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'


const ProfilePage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Redirect to Overview by default if no sub-route is selected
    useEffect(() => {
        if (location.pathname === '/profile') {
            navigate('/profile/overview');
        }
    }, [location, navigate]);

    
    // handle logout
    const handleLogout = () => {
        // localStorage.removeItem('token')
        navigate('/auth')
    }
    return (
        <div className='profilePage'>
            <div className='profilePage-content'>
                <div className="nav-column">
                    <ul>
                        <div>
                            <li><NavLink to="/profile/overview" className={({ isActive }) => isActive ? 'active' : ''}>Overview</NavLink></li>
                        </div>
                        <hr/>
                        <div>
                            <li><NavLink to="/profile/orders" className={({ isActive }) => isActive ? 'active' : ''}>Orders</NavLink></li>
                            <li><NavLink to="/profile/messages" className={({ isActive }) => isActive ? 'active' : ''}>Messages</NavLink></li>
                        </div>
                        <hr/>
                        <div>
                            <li><NavLink to="/profile/wishlist" className={({ isActive }) => isActive ? 'active' : ''}>Wishlist</NavLink></li>
                            <li><NavLink to="/profile/reviews" className={({ isActive }) => isActive ? 'active' : ''}>Review Purchases</NavLink></li>
                        </div>
                        <hr/>
                        <div>
                            <li><NavLink to="/profile/help" className={({ isActive }) => isActive ? 'active' : ''}>Help</NavLink></li>
                            <li><NavLink to="/profile/suggestions" className={({ isActive }) => isActive ? 'active' : ''}>Suggestions</NavLink></li>
                            <li><NavLink to="/profile/settings" className={({ isActive }) => isActive ? 'active' : ''}>Settings</NavLink></li>
                        </div>
                        <hr />
                    </ul>
                    <button className="logout-button" onClick={handleLogout}>Logout</button>
                </div>
                
                <div className="content-column">
                    <Outlet /> {/* This is where the selected component (e.g. Overview, Orders) will render */}
                </div>
            </div>
        </div>
    )
}

export default ProfilePage
