import React from 'react'
import "./SideBanner.css"
import sideBanner1 from '../../assets/images/side-banner1.png'
import sideBanner2 from '../../assets/images/side-banner2.png'


const SideBanner = () => {
    return (
        <div className='side-banner-container'>
            <div className='side-banner1'>
                <div className='side-banner1-text'>
                    <p>Best Sellers in Phones</p>
                </div>
                <img src={sideBanner1} alt="top side banner" />
            </div>
            <div className='side-banner2'>
                <img src={sideBanner2} alt="bottom side banner" />
                <div className='side-banner2-text'>
                    <p>Beauty on Budget</p>
                    <p className='effect'><span className='medium-text'>Up to</span> <span className='bold-text'>50% off</span></p>
                </div>
            </div>
        </div>
    )
}

export default SideBanner
