import React, { useEffect, useState } from 'react'
import './Wishlists.css'
import axios from 'axios';
import WishlistModal from '../wishlistModal/WishlistModal';

const Wishlists = () => {
    const [wishlistItems, setWishlistItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    
    // fetch wishlist items
    useEffect(() => {
        const getWishlistItems = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = await response.data;

                setWishlistItems(data.wishlist);
                
            } catch (error) {
                console.error('Error fetching wishlist items:', error);
            } finally {
                setLoading(false);
            }
        }
        getWishlistItems();
    }, []);


    // open modal when delete button is clicked
    const handleDeleteClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    // close the modal
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };

    // Handle removing an item from wishlist
    const handleRemoveItem = () => {
        setWishlistItems(wishlistItems.filter((item) => item.id !== selectedItem.id));
        handleCloseModal();
    };

    // Handle adding an item to the cart
    const handleAddToCart = (itemId) => {
        const item = wishlistItems.find((item) => item.id === itemId);
        console.log('Adding to cart:', item);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="wishlist-container">
            <h2>Your Wishlist</h2>
            {wishlistItems.length === 0 ? (
                <p>Your wishlist is empty.</p>
            ) : (
                <div className="wishlist-items">
                    {wishlistItems.map((item) => (
                        <div className="wishlist-item" key={item.id}>
                            <img src={item.productImage} alt={item.productName} className="product-image" />
                            <div className="product-details">
                                <h3>{item.productName}</h3>
                                <p>GH₵{item.price}</p>
                            </div>
                            <div className="wishlist-actions">
                                <button className="add-to-cart-btn" onClick={() => handleAddToCart(item.id)}>
                                    Add to Cart
                                </button>
                                <button className="delete-btn" onClick={() => handleDeleteClick(item)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* modal for delete confirmation */}
            <WishlistModal
                showModal={showModal}
                onClose={handleCloseModal}
                onDelete={handleRemoveItem}
                itemName={selectedItem ? selectedItem.productName : ''}
            />
        </div>
    )
}

export default Wishlists
