import React from 'react'
import './FlashDeals.css'
import { Link } from 'react-router-dom'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'

const FlashDeals = () => {
    return (
        <div className='flashDeals'>
            <div className='flashDeals-header'>
                <h1>Flash Deals</h1>
                <Link to={'./flash-deals'}><ViewAllButton /></Link>
            </div>
            <div className='flashDeals-container'></div>
        </div>
    )
}

export default FlashDeals
