import React from 'react'
import './FlashDealsPage.css'

const FlashDealsPage = () => {
    return (
        <div className='flash-deals-page'>
            <h1>Flash Deals Page</h1>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default FlashDealsPage
