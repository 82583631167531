import './App.css';
import Headroom from 'react-headroom';
import Header from './containers/header/Header';
import Main from './pages/Main';
import Footer from './components/footer/Footer';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import BestSellersPage from './pages/bestSellersPage/BestSellersPage';
import CatMenuModal from './components/catMenuModal/CatMenuModal';
import BestDealsPage from './pages/bestDealsPage/BestDealsPage';
import StoresPage from './pages/storesPage/StoresPage';
import TopPicksPage from './pages/topPicksPage/TopPicksPage';
import FlashDealsPage from './pages/flashDealsPage/FlashDealsPage';
import Products from './pages/products/Products';
import ProductDetails from './pages/productDetailsPage/ProductDetails';
import AuthPage from './pages/AuthPage/AuthPage'; 
import CategoryPage from './pages/categoryPage/CategoryPage';
import ProfilePage from './pages/profilePage/ProfilePage';
import ProfileOverview from './components/profileOverview/ProfileOverview';
import Orders from './components/orders/Orders';
import Messages from './components/messages/Messages';
import Wishlists from './components/wishlist/Wishlists';
import Reviews from './components/reviews/Reviews';
import Help from './components/help/Help';
import Suggestions from './components/suggestions/Suggestions';
import Settings from './components/settings/Settings';
import OrderDetails from './components/orderDetails/OrderDetails';


function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  )
}


function AppContent() {
  const location = useLocation();

  // Define routes where you don't want to show Header and Footer
  const hideHeaderFooterRoutes = ['/auth'];

  return (
    <div className='app'>
        {/* Conditionally render Header */}
        {!hideHeaderFooterRoutes.includes(location.pathname) && (
          <Headroom>
            <Header />
          </Headroom>
        )}
        <Routes>
          <Route index path='/'element={<Main />}/>
          <Route path='/profile' element={<ProfilePage />}>
            <Route path="/profile/overview" element={<ProfileOverview />} />
            <Route path="/profile/orders" element={<Orders />} />
            <Route path="/profile/orders/:orderId" element={<OrderDetails />} />
            <Route path="/profile/messages" element={<Messages />} />
            <Route path="/profile/wishlist" element={<Wishlists />} />
            <Route path="/profile/reviews" element={<Reviews />} />
            <Route path="/profile/help" element={<Help />} />
            <Route path="/profile/suggestions" element={<Suggestions />} />
            <Route path="/profile/settings" element={<Settings />} />
          </Route>
          <Route path='/best-deals-all'element={<BestDealsPage />}/>
          <Route path='/stores'element={<StoresPage />}/>
          <Route path='/top-picks'element={<TopPicksPage />}/>
          <Route path='/best-sellers'element={<BestSellersPage />}/>
          <Route path='/flash-deals'element={<FlashDealsPage />}/>
          <Route path='/categories' element={<CatMenuModal />}/>
          <Route path='/products' element={<Products />} />
          <Route path='/product/:id' element={<ProductDetails />} />
          <Route path='/auth' element={<AuthPage />} />
          <Route path='/category/:category' element={<CategoryPage products={Products} />} />
          <Route path='*' element={<h1>Not Found</h1>} />
        </Routes>
        {/* Conditionally render Footer */}
        {!hideHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default App;
