import React from 'react'
import './AuthPage.css'
import LoginForm from '../../components/loginForm/LoginForm'
import SignupForm from '../../components/signupForm/SignupForm'
import { useState } from 'react'
import buyerImage from '../../assets/images/buyer-login-image.png'
import sellerImage from '../../assets/images/seller-login-image.png'
import authLogo from '../../assets/Footer Logo.svg'
import authStoreIcon from '../../assets/icons/hugeicons_store-01.svg'
import { GrCart } from "react-icons/gr";
import { Link } from 'react-router-dom'
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg'


const AuthPage = () => {
    // default state: login view and buyer role pre-selected
    const [isLogin, setIsLogin] = useState(true); // Default to login view
    const [role, setRole] = useState(''); // No role selected by default
    const [formVisible, setFormVisible] = useState(false); // Track if form is visible
    
    // toggle between login and signup views
    const toggleAuthView = () => {
        setIsLogin(!isLogin);
        setRole(''); // Reset role selection
        setFormVisible(false); // Hide form when toggling views
    };

    // Function to handle role selection and display the form
    const handleRoleSelection = (selectedrole) => {
        setRole(selectedrole);
    }

    // function to handle selection submission
    const handleSelectionSubmit = () => {
        setFormVisible(true); // Show form when role is selected
    };

    // function to render dynamic images based on user role
    const renderRoleImage = () => {
        if (role === 'buyer') {
            return <img src={buyerImage} alt="BuyerImage" className='role-image' />;
        } else if (role === 'seller') {
            return <img src={sellerImage} alt="SellerImage" className='role-image' />;
        } else {
            return <img src={buyerImage} alt="BuyerImage" className='role-image' />;
        }
    };

    // Function to handle going back to role selection
    const goBackToRoleSelection = () => {
        setRole(''); // Reset role selection
        setFormVisible(false); // Hide form
    };
    
    return (
        <div className='auth-page-container'>
            <div className="auth-left-column">
                <div className="auth-header">
                    <Link to={'/'} className='auth-logo'>
                        <img src={authLogo} alt='AuthLogo' />
                    </Link>
                    <div className='auth-header-text'>
                        <h1>{isLogin ? 'Don’t have an account?' : 'Already have an account?'}</h1>
                        <button onClick={toggleAuthView}>
                            {isLogin ? 'Sign Up' : 'Login'}
                        </button>
                    </div>
                </div>
                <div className='auth-content'>
                    {!formVisible ? (
                        // Display role selection buttons if form is not visible
                        <div className="role-selection">
                            <div className='role-selection-header'>
                                <h1>{isLogin ? 'Welcome back' : 'How do you plan to use Kokorko?'}</h1>
                                <h2>{isLogin ? 'I am logging in as a...' : 'Select the option that best describes your goals'}:</h2>
                            </div>
                            <div className={isLogin ? 'role-buttons' : 'role-buttons-signup'}>
                                <button 
                                    className={role === 'buyer' ? 'active' : ''} 
                                    onClick={() => handleRoleSelection('buyer')}>
                                        
                                        <div className='buyer-icon-text-container'>
                                            <div className={`buyer-icon-container ${role === 'buyer' ? 'active' : ''} `}>
                                                <GrCart className={`buyer-icon ${role === 'buyer' ? 'active' : ''}`} color='#222222' />
                                            </div>
                                            <div>
                                                <p className='buyer-icon-text'>Buyer</p>
                                                <p className='buyer-icon-text-desc'>{!isLogin ? 'Search, Discover, and Purchase' : ' '}</p>
                                            </div>
                                        </div>
                                        <input type="checkbox" checked={role === 'buyer'} readOnly/>
                                </button>
                                <button 
                                    className={role === 'seller' ? 'active' : ''} 
                                    onClick={() => handleRoleSelection('seller')}>
                                        
                                        <div className='buyer-icon-text-container'>
                                            <div className={`buyer-icon-container ${role === 'seller' ? 'active' : ''} `}>
                                                {/* <GrCart className={`buyer-icon ${role === 'seller' ? 'active' : ''}`} color='#222222' /> */}
                                                <img src={authStoreIcon} alt="AuthStoreIcon" className={`buyer-icon ${role === 'seller' ? 'active' : ''}`}  />
                                            </div>
                                            <div>
                                                <p className='seller-icon-text'>Seller</p>
                                                <p className='buyer-icon-text-desc'>{!isLogin ? 'Grow Your Business' : ' '}</p>
                                            </div>
                                        </div>
                                        <input type="checkbox" checked={role === 'seller'} readOnly/>
                                </button>
                            </div>
                            <div className='continue-button'>
                                <button onClick={handleSelectionSubmit}>Continue</button>
                            </div>
                        </div>
                    ):(
                        // display form when a role is selected
                        <div className="auth-form">
                            {isLogin ? (
                                <LoginForm role={role} />
                            ) : (
                                <SignupForm role={role} />
                            )}
                            <button className="back-button" onClick={goBackToRoleSelection}>
                                <img src={ChevronLeftIcon} alt="Back" />
                                Back
                            </button>
                        </div>
                    )}  
                </div>
            </div>
            <div className="auth-right-column">
                {renderRoleImage()} {/* Display dynamic image based on role */}
            </div>
        </div>
    )
}

export default AuthPage
