import React from 'react'
import './Footer.css'
import FooterLogo from '../../assets/Footer Logo.svg'

const Footer = () => {
    let date = new Date().getFullYear();

    return (
        <div className='footer'>
            <div className='footer-top'>
                <div className='top-left'>
                    <div className='footer-logo'>
                        <img src={FooterLogo} alt='footer logo' />
                    </div>
                    <div className='footer-nav'>
                        <ul>
                            <li>Home</li>
                            <li>About Us</li>
                            <li>Contact</li>
                            <li>Vbuy</li>
                            <li>Help & Supprt</li>
                            <li>Privacy</li>
                        </ul>
                    </div>
                </div>
                <div className='top-right'>
                    <h1>Keep up with us</h1>
                    <div className='newsletter'>
                        <input type='text' placeholder='Email address'/>
                        <button>Sign up</button>
                    </div>
                </div>
            </div>
            <div className='footer-bottom-container'>
                <hr />
                <div className='footer-bottom'>
                    <div className='bottom-left'>
                        <span>&copy; {date}</span><span>Kokorko - All rights reserved</span>
                    </div>
                    <div className='bottom-right'>
                        <p>Terms & Conditions</p>
                        <p>Cookies</p>
                        <p>Privacy Policy</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
