import React from 'react'
import './StoresPage.css'

const StoresPage = () => {
    return (
        <div className='stores-page'>
            <h1>Stores Page</h1>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default StoresPage
